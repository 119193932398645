import React, {useRef, useState} from 'react';
import {Upload, Icon, Modal, Button} from 'antd'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import 'antd/dist/antd.css'
import './App.css';
import avatar from './avatar.jpg'
import border from './border.png'

function App() {
  const borderRef = useRef()
  const imageRef = useRef()
  const [out, setOut] = useState()
  const [up, setUp] = useState()
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    aspect: 1,
  })
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{color: 'white'}}>头像自动加框</h1>
        <img src={avatar} style={{width: 200, height: 200, marginBottom: 30}} />
        <img ref={borderRef} src={border} style={{width: 1000, height: 1000, display: 'none'}} />
        <Upload.Dragger
          beforeUpload={file => {
            const reader = new FileReader()
            reader.onload = function (event) {
              const img = new Image()
              img.onload = () => setUp(reader.result)
              img.src = event.target.result
            }
            reader.readAsDataURL(file)
            return false
          }}
          accept='image/*'
          showUploadList={false}
          style={{backgroundColor: 'gray', borderRadius: 4, opacity: 0.5, border: '2px dashed white'}}
        >
          <div style={{cursor: 'pointer', color: 'white', margin: '30px 100px'}}>
            <Icon type="upload" style={{fontSize: 50}}/>
            <p style={{fontSize: 20}}>点击或拖拽上传</p>
          </div>
        </Upload.Dragger>
        <Modal visible={out} footer={null} onCancel={()=>setOut()}>
          <img src={out} style={{ maxWidth: '100%' }} />
        </Modal>
        <Modal visible={up} footer={<Button type='primary' onClick={()=>{
          const image = imageRef.current
          const scaleX = image.naturalWidth / image.width
          const scaleY = image.naturalHeight / image.height
          const canvas = document.createElement('canvas');
          canvas.width = 1000
          canvas.height = 1000
          const ctx = canvas.getContext('2d')
          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0, 0, 1000, 1000,
          )
          ctx.drawImage(borderRef.current, 0, 0)
          var imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          var d = imgData.data;
          // loop through all pixels
          // each pixel is decomposed in its 4 rgba values
          for (var i = 0; i < d.length; i += 4) {
            // get the medium of the 3 first values ( (r+g+b)/3 )
            var med = (d[i] + d[i + 1] + d[i + 2]) / 3;
            // set it to each value (r = g = b = med)
            d[i] = d[i + 1] = d[i + 2] = med;
            // we don't touch the alpha
          }
          // redraw the new computed image
          ctx.putImageData(imgData, 0, 0);
          const link = document.createElement('a');
          link.download = 'avatar.jpg'
          link.href = canvas.toDataURL("image/jpeg");
          link.click()
          setUp()
          setOut(link.href)
        }}>完成并下载</Button>} onCancel={() => setUp()}>
          {/*<img src={out} style={{height: '80%', width: '80%'}} />*/}
          <ReactCrop
            src={up}
            crop={crop}
            ruleOfThirds
            onChange={setCrop}
            onImageLoaded={img => imageRef.current = img}
          />
        </Modal>
      </header>
    </div>
  );
}

export default App;
